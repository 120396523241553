.card-active {
	background-size: cover;
	background-image: url("/src/assets/selectors/selector-active.png");
	height: 214px;
	width: 313px;
	/*border-radius: 12px;*/
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.3s all;
}

.card-disabled {
	background-size: cover;
	background-image: url("/src/assets/selectors/selector-default.png");
	height: 180px;
	width: 263px;
	/*border-radius: 12px;*/
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.3s all;
}

.card-text {
	font-size: 38px;
	font-weight: 400;
	font-family: Gunterz;
	color: var(--white);
}

.card-active .card-text {
	color: var(--primary);
}

.--text-disabled {
	opacity: 0.5
}
