@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,700;1,700&display=swap');

html {
	height: 100vh;
	height: -webkit-fill-available;
}

body, #root {
	height: 100vh;
	height: -webkit-fill-available;
	max-height: 100vh;
	max-height: -webkit-fill-available;
	display: flex;
	flex-direction: column;
}

body {
	--default-font-family: Helvetica, Arial, sans-serif;
	margin: 0;
	font-family: var(--default-font-family);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;

	--white: #FFFFFF;
	--primary: #E5077E;
	--accent: #0178B4;
	--davyGray: #575757;
	--disabledAccent: rgb(141, 90, 122);
	--completed: #e2f1db;
	--green: #60BD40;
	--text: #525252;
}

* {
	font-family: var(--default-font-family);
	color: var(--text);
}

#root {
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

@font-face {
	font-family: 'Gunterz';
	src: local('Gunterz'), url(./assets/fonts/Gunterz-Bold.otf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url(./assets/fonts/Helvetica-Now.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}


p {
	margin-block-start: 0;
	margin-block-end: 0;
}

