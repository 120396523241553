.background {
	background: #FFFFFF;
	border-radius: 14px;
	margin: 30px 58px 0 58px;
	position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100%;
	overflow-y: auto;
}

.background__logo {
	height: 60px;
	position: absolute;
	z-index: 10;
	top: 35px;

	/*RIGHT IS HALF OF PARENT WIDTH MINUS HALF OF ELEMENT WIDTH*/
	right: calc(50% - 131px);
}

.background__title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 42px;
	margin-top: 12px;
	color: var(--text);
}

.background__title-text {
	font-family: Helvetica;
	font-weight: 400;
	font-size: 22px;
}

.background__title-text.titleOnly {
	font-family: Gunterz;
}

.background__container {
	padding: 36px 32px 0 32px;
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;
	box-sizing: border-box;
}

.modal-container-qr {
	z-index: 2;
	position: absolute;
	background: url("/src/assets/modal/modal-background.png") no-repeat center center / contain;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 800px;
	height: 500px;
	left: 0;
	right: 0;
	margin: 0 auto;
	margin-top: 60px;
}

.modal-content-qr {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	font-weight: bold;
}

.link-qr {
	width: 50%;
	margin-bottom: 10px;
}
