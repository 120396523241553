.container {
	background: var(--white);
	border: 2px solid var(--primary);
	background-size: 100% 100%;
	aspect-ratio: 641/100;
	max-width: 800px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	border-radius: 12px;
	cursor: pointer;
}

.completed {
	background: var(--completed);
	border: 1px solid var(--green);
	background-size: 100% 100%;
	aspect-ratio: 641/100;
	max-width: 800px;
}

.container__title {
	font-family: Noto Sans;
	color: var(--accent);
	font-size: 18px;
	font-weight: 600;
	padding-bottom: 10px;
}

.container__text {
	color: var(--text);
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	box-sizing: border-box;
}
