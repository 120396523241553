
.title {
	z-index: 1;
	position: relative;
}

.title:before {
	content: "";
	display: block;
	width: 120px;
	height: 98px;
	background: url("/src/assets/body/blob.png") no-repeat center center / contain;
	position: absolute;
	top: -26px;
	right: calc(50% + 50px);
	z-index: -1;
}

.title:after {
	content: "";
	display: block;
	width: 122px;
	height: 3px;
	background-color: var(--primary);
	position: absolute;
	margin-top: 10px;
	right: calc(50% - 61px);
}
