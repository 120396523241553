.container-vod {
	background: url("/src/assets/mito-card.png") no-repeat;
	background-size: contain;
	height: 205px;
	width: 300px;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: default;
	transition: 0.3s all;
}

.verdad {
	background: url("/src/assets/verdad-card.png") no-repeat;
	background-size: contain;
	color: var(--primary);
}

.selected {
	height: calc(300px * 0.6859);
	width: 300px;
}

.not-selected {
	height: calc(260px * 0.6859);
	width: 260px;
	opacity: 0.5;
}

.card-text-vod {
	font-size: 42px;
	font-weight: 700;
	font-family: Poppins;
	text-transform: uppercase;
	letter-spacing: 10px;
	color: var(--primary);
}

.verdad .card-text-vod,
.verdad .card-result-vod {
	color: var(--accent);
}

.card-result-vod {
	font-size: 20px;
	font-family: Poppins;
	font-weight: 700;
	color: var(--primary);
	text-transform: uppercase;
	letter-spacing: 10px;
}
